@import './variables.scss';

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(1px, 0, 0);
  }
}

body {
  font-family: 'Cairo', sans-serif;
  padding: 0;
  margin: 0;
}

input {
  font-family: 'Cairo', sans-serif;
}

.appWrapper {
  // This is the lightest way to prevent accidental double-tap zoom,
  // while still allowing pinch zoom
  touch-action: manipulation;
  margin: 0 auto;
}

.icon {
  background-size: contain;
  background-position: center center;
  display: inline-block;
  background-repeat: no-repeat;
}

.icon.star {
  background-image: url('../img/star.svg');
}

.icon.trophy {
  background-image: url('../img/trophy.svg');
}

.icon.searchError {
  background-image: url('../img/search-problem.svg');
  height: 25px;
  margin-right: 5px;
  width: 25px;
  display: block;
}

.icon.backspace {
  width: 50px;
  height: 50px;
  background-image: url('../img/backspace.svg');
  transition: .1s;
  transform: scale(1);
}

.icon.error {
  width: 40px;
  height: 40px;
  background-image: url('../img/error.svg');
  animation: shake 1s;
}

.icon.angle {
  background-image: url('../img/angle.svg');
}

.icon.brand {
  background-image: url('../img/brand-large.png');
}

.icon.pair {
  background-image: url('../img/icon-pair-white.svg');
  width: 25px;
  height: 30px;
  background-size: contain;
}

@keyframes ripple {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.loading {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  transform: translate(-33.5px, -33.5px) scale(0.335) translate(33.5px, 33.5px);
  .circle {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border-color: #ffffff;
    &.green {
      border-color: $green;
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
}



.icon.joystick {
  width: 50px;
  height: 50px;
  background-image: url('../img/joystick.svg');
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 15;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity .4s;
  pointer-events: none;
  background-color: rgba(25,50,74,.95);
  &.active {
    opacity: 1;
    pointer-events: all;
    .icon {
      animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    }
  }
  .icon {
    margin: 0 auto;
    display: block;
  }
  p {
    line-height: 28px;
    font-size: 24px;
    max-width: 500px;
    color: white;
    margin: 20px auto;
    @media (max-width: 767px) {
      line-height: 22px;
      font-size: 18px;
      max-width: 340px;
    }
  }
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    .button {
      max-width: 135px;
      font-size: 16px;
      margin: 20px 10px !important;
    }
  }
  &.left {
    justify-content: flex-start;
  }
  .button {
    margin: 30px 50px;
  }
}

.page.idle {
  display: flex;
  padding-top: 0px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  top: 0;
  position: fixed;
  background-color: $blue;
  left: 0;
  right: 0;
  z-index: 10;
  transition: .5s;
  .idleLogo {
    background-image: url('../img/brand-large.png');
    height: 400px;
    width: 225px;
    margin: 0 auto;
    display: block;
    animation: pulse 10s infinite;
    @media (max-width: 767px) {
      height: 150px;
    }
  }
}

.page {
  padding-top: 110px;
}

.button {
  border-radius: 150px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  width: 200px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
  cursor: pointer;
  font-family: 'Cairo';
  transition: opacity 0.15s;
  user-select: none;
  &.disabled,
  &[disabled] {
    opacity: .25;
    pointer-events: none;
  }
  &.error {
    background-color: $red;
    border: none;
  }
  &.small {
    border: 2px solid $light-gray;
    padding: 0px 10px;
    color: $light-gray;
    margin: 0 auto;
    display: block;
    &:active {
      background-color: $light-gray;
      color: white;
    }
  }
  &.primary {
    border-width: 0;
    background-color: $green;
  }
  &.secondary {
    background-color: rgba(0,0,0,0);
    border: 2px solid $green;
    color: $green;
    &:active {
      color: white;
      background-color: $green;
    }
    &.white {
      background-color: rgba(0,0,0,0);
      color: white;
      border: 2px solid white;  
    }
  }
  
}

.direction {
  background-image: url('../img/rotation.svg');
  width: 35px;
  height: 35px;
  margin: 0 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform 0.3s;
  display: block;
  cursor: pointer;
  &.white {
    background-image: url('../img/rotation-white.svg');
  }
  &.ccw {
    transform: scaleX(-1);
  }
}

.returnHome {
  p {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    line-height: 22px;
    font-size: 18px;
    @media (max-width: 767px) {
      max-width: 250px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.rideSets {
  padding: 15px 0 0;
  margin: 0;
  li {
    position: relative;
    display: flex;
    padding: 15px 15px;
    line-height: 40px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 12px;
      margin-bottom: -20px;
    }
    &.active, &.complete {
      color: $green;
      input {
        color: $green !important;
      }
      .direction {
        background-image: url('../img/rotation-green.svg');
      }
      &:before {
        background-color: $green;
        border: 1px solid $green;
      }
    }
    &.complete {
      &:after {
        background-color: $green;
      }
    }

    .parameter {
      display: flex;
      padding-left: 10px;
      align-items: center;
      &:first-child {
        padding-left: 0px;
      }
      .number {
        width: 40px;
        text-align: center;
        font-size: 36px;
        opacity: 1;
        position: relative;
        font-weight: 500;
        line-height: 36px;
        margin: 0px 15px;
      }
      .direction {
        margin: 0px 10px 0px 15px;
      }
      span {
        font-weight: 500;
        margin-right: 10px;
        display: block;
        opacity: 0;
        top: 0;
        position: absolute;
        transition: .3s;
      }
      label {
        padding-top: 0;
        margin-bottom: 0;
        position: relative;
      }
      &.ccw {
        line-height: 40px;
        label {
          padding-top: 10px;
          margin-bottom: -10px;
        }
        span {
          top: -10px;
          opacity: 1 !important;
        }
      }
    }
  }
}