@import '../styles/variables';

.parameter-input {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  label {
    margin-left: 5px;
  }

  input {
    &[type='number'] {
      border: 0;
      border-radius: 6px;
      padding: 5px !important;      
      -moz-appearance: textfield;
      display: inline-block;
      font-family: 'Cairo', sans-serif;
      font-size: 36px;
      height: 52px;
      line-height: 36px;
      margin: 0;
      text-align: center;
      width: 65px;
      @media (max-width: $mobile) {
        width: 35px;
      }
      @media (max-width: $desktop) {
        font-size: 24px;
        height: 50px;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .stepper {
    -webkit-user-select: none;
    background-image: url('../images/caret.svg');
    background-position: left 50% center;
    background-repeat: no-repeat;
    background-size: 10px;
    border: 2px solid $light-gray;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    // Don't let a flex layout squeeze this element
    flex-shrink: 0;
    height: 35px;
    margin: 0 5px;
    user-select: none;
    width: 35px;

    &.less {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    .stepper {
      opacity: .2;
      pointer-events: none;
    }
  }

  &.white {
    .stepper {
      background-image: url('../images/caret-white.svg');
    }
  }

  @media (max-width: $tablet) {
    .stepper {
      display: none;
    }
  }
}
