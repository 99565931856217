@import './variables.scss';

.page.machineSelect {
  padding: 80px 0px 135px;
  p {
    text-align: center;
  }
  .modal {
    .machineName {
      font-size: 18px;
      text-transform: uppercase;
      height: 18px;
      margin-bottom: 10px;
    }
    span {
      text-transform: uppercase;
      color: white;
      font-weight: bold;
      display: inline-block;
      color: $green;
    }
  }

  .icon {
    background-position: right center;
  }

  .icon.pair {
    background-image: url(../img/icon-pair-disabled.svg);
    margin-right: 5px;
  }

  .icon.idle {
    width: 20px;
    height: 20px;
    background-size: 40px;
  }

  .prompt {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 140px;
    border-top: 1px solid $light-gray;
    background-color: white;
    transition: .3s;
    z-index: 8;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .message {
      height: 20px;
      @media (max-width: 767px) {
        height: 40px;
        padding: 0px 5px;
        line-height: 20px;
      }
    }
    p {
      margin-top: 10px;
    }
    &.show {
      bottom: 0;
    }
  }
  .machines {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    @media (max-width: 767px) {
      td {
        font-size: 14px;
        padding: 20px 0px;
      }
    }
    .item {
      border-top: 1px solid $light-gray;
      opacity: .8;
      font-size: 18px;
      color: $blue;
      cursor: pointer;
      user-select: none;
      background-color: white;
      &:last-child {
        border-bottom: 1px solid $light-gray;
      }
      &.offline {
        opacity: .25;
        pointer-events: none;
      }
      &.active {
        background-color: $green;
        color: white;
        opacity: 1;
        .groupName, .status, .currentUser, .agent, .network {
          color: white !important;
        }
        .icon.pair {
          background-image: url(../img/icon-pair-white.svg) !important;
        }
        .status .icon.idle {
          background-position: left center;
        }
        .network .icon {
          background-position: right center;
        }
      }
      .button {
        width: auto;
        padding: 0 2vw;
        margin-left: 20px;
      }
      .network {
        color: $green;
          &.offline {
            color: #888;
          }
        .icon {
          width: 20px;
          height: 20px;
          background-image: url('../img/sprite-wifi.svg');
          margin-right: 5px;
          position: relative;
          top: 2px;
          background-size: 40px;
          background-position: left center;
          &.offline {
            background-size: contain;
            background-position: center center;
            background-image: url('../img/wifi-offline.svg');
          }
        }
      }
      .currentUser {
        color: #888;
        font-weight: normal;
        text-transform: none;
        &.active {
          color: $green;
          .icon.pair {
            background-image: url(../img/icon-pair-green.svg)
          }
        }
        .user {
          display: flex;
          align-items: center;
        }
        .agent {
          font-style: italic;
          color: #888;
          font-size: 14px;
          line-height: 14px;
        }
      }
      .status {
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
        margin-right: 5vw;
        .icon {
          background-image: url('../img/brand-blue-placeholder.png');
          margin-right: 5px;
          position: relative;
          top: 4px;
          width: 20px;
          height: 20px;
          &.idle {
            background-image: url('../img/sprite-idle.svg');
          }
        }
        &:before {
          width: 25px;
          height: 25px;
          content: '';
          display: block;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          left: -28px;
          top: 5px;
        }
        &.false {
          color: $green;
        }
      }
      .name {
        width: 20vw;
        padding: 0 5vw 0 2vw;
        @media (max-width: 767px) {
          flex: 1;
        }
        span {
          display: block;
          line-height: 16px;
        }
        .groupName {
          font-weight: bold;
          text-transform: uppercase;
          margin-top: 5px;
          color: #888;
          font-size: 14px;
        }
      }
      td.photo {
        width: 100px;
        @media (max-width: 767px) {
          display: none;
        }
        img {
          vertical-align: middle;
          margin: 1vw 2vw;
          display: inline-block;
          height: 100px;
        }
      }
    }
  }
}
